<template>
    <section>
        <template v-if="seccionIngreso">
            <div class="row mx-0 f-30 f-600 text-general justify-center mt-3 mb-3">
                Iniciar con {{ tipoIngreso == 1 ? 'celular' : tipoIngreso == 2 ? 'correo' : '' }}
            </div>
            <div id="sign-in-button" />
            <div class="row mx-0 justify-center mb-4">
                <img
                :src="`/img/icons/${tipoIngreso == 1 ? 'smartphone' : tipoIngreso == 2 ? 'email' : ''}.png`"
                style="max-width:100%;height:70px;"
                />
            </div>
            <div class="form-group d-middle-center">
                <div class="position-relative" style="width:304px;">
                    <div v-if="existeErrorSesionExiste" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                        <p class="text-general-red f-30 f-600 mt-3 text-center">
                            ¿Seguro eres tú?
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            No logro recordarte, asegúrate de <br /> escribir bien tu usuario.
                        </p>
                    </div>
                    <div v-else-if="existeErrorSesionTipoAdmin" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                        <p class="text-general-red f-30 f-600 mt-3 text-center">
                            Espera
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            Estás intentando ingresar como un administrador.
                        </p>
                        <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.admin'})">
                            Ir a la aplicación de administrador
                        </p>
                    </div>
                    <div v-else-if="existeErrorSesionTipoLeechero" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                        <p class="text-general-red f-30 f-600 mt-3 text-center">
                            Espera
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            Estás intentando ingresar como un {{$config.vendedor}}.
                        </p>
                        <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.leechero', params: { ingresoLeechero: false }})">
                            Ir a la aplicación de {{$config.vendedor}}
                        </p>
                    </div>
                    <div v-else-if="existeErrorSesionTipoCliente" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                        <p class="text-general-red f-30 f-600 mt-3 text-center">
                            Espera
                        </p>
                        <p class="text-general f-15 mt-1 f-500 text-center">
                            Estás intentando ingresar como un cliente.
                        </p>
                        <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.cliente'})">
                            Ir a la aplicación de cliente
                        </p>
                    </div>
                    <div v-if="tipoIngreso == 1" class="col-12 px-0 position-relative py-2">
                        <el-select
                        v-model="pais"
                        placeholder="Seleccione un país"
                        filterable
                        class="w-100 br-6"
                        >
                            <el-option
                            v-for="(item, i) in proyecto.paises"
                            :key="i"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                    <el-input
                    v-model="payload.usuario"
                    :disabled="cargando"
                    :placeholder="`${tipoIngreso == 1 ? 'Número celular' : tipoIngreso == 2 ? 'Correo Electronico' : ''}`"
                    class="w-100 br-6"
                    maxlength="70"
                    />
                </div>
            </div>
            <div class="row mx-0 justify-center my-3">
                <error-alert v-if="showErrorAlert" :tipo="errorAlertType" :titulo="errorAlertType == 4 ? 'Error' : 'Espera' " />
                <el-button
                v-loading="cargando"
                class="btn-general"
                style="height:44px;width:304px"
                @click="handle()"
                >
                    Enviar códígo de verificación
                </el-button>
            </div>
            <div class="row mx-0 align-items-center px-3 mb-3">
                <div class="col">
                    <hr class="border" />
                </div>
                o
                <div class="col">
                    <hr class="border" />
                </div>
            </div>
            <div class="row mx-0 justify-center">
                <img src="/img/auth/facebook.svg" style="max-width:100%;height:45px;" class="obj-cover mx-3 cr-pointer" @click="iniciarSesionFacebook" />
                <img src="/img/auth/google.svg" style="max-width:100%;height:45px;" class="obj-cover mx-3 cr-pointer" @click="iniciarSesionGoogle" />
                <img
                :src="`/img/icons/${tipoIngreso == 1 ? 'email' : tipoIngreso == 2 ? 'smartphone' : ''}.png`"
                style="max-width:100%;height:45px;"
                class="obj-cover mx-3"
                @click="cambioIngreso"
                />
            </div>
            <modal-codigo-verificacion-login ref="CodigoVerificacionLogin" />
        </template>
        <verificar-codigo-login v-else />
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import firebase from '~/library/firebase';
import authService from '~/services/auth';
import ServicioApi from "~/services/tiendas/postulacion";
import registroService from '~/services/registro/registro'

const VUEX_RUTA = 'auth'
export default {
    components: {
        modalCodigoVerificacionLogin: () => import('./modalCodigoVerificacionLogin' ),
        errorAlert: () => import("./errorAlert"),
        verificarCodigoLogin: () => import('./verificarCodigoLogin')
    },
    data(){
        return {
            payload: {
                usuario: '',
            },
            stateRoute: this.$route.params.texto,
            contrasenaVisible: false,
            cargando: false,
            erroresSesion: ['U001', 'UT001', 'UT002', 'UT003', 'C001'],
            estadoPermitidos: [4, 5],
            errores: {
                sesion: ['usuario', 'contrasena'],
                general: ['proyecto', 'tipo']
            },
            tipoIngreso: 1,
            tipoLogin: 1,
            pais: '',
            proyecto: {
                datos: {},
                paises: [],
            },
            showErrorAlert: false,
            errorAlertType: 1,
            seccionIngreso: true,
        }
    },
    computed: {
        ...mapGetters({
            error: `${VUEX_RUTA}/obtenerError`,
        }),
        existenErrores(){
            return !_.isEmpty(this.error)
        },
        existeErrorSesion(){
            return this.existenErrores && this.erroresSesion.includes(this.error.codigo)
        },
        existeErrorSesionTipoLeechero(){
            return this.existenErrores && this.erroresSesion[2].includes(this.error.codigo)
        },
        existeErrorSesionTipoCliente(){
            return this.existenErrores && this.erroresSesion[3].includes(this.error.codigo)
        },
        existeErrorSesionTipoAdmin(){
            return this.existenErrores && this.erroresSesion[1].includes(this.error.codigo)
        },
        existeErrorSesionExiste(){
            return this.existenErrores && this.erroresSesion[0].includes(this.error.codigo)
        },
        existeErrorServidor(){
            return this.existenErrores && this.error.tipo === 500
        },
        setImage(){
            let url = this.stateRoute == 1 ? 'login_files/cellphone.svg' : 'modales/mail.svg';
            return url ;
        }
    },
    watch: {
        'payload.usuario'(){
            this.removerErrores();
        },
        '$route.name'(){
            this.removerErrores();
        }
    },
    async mounted(){
        this.removerErrores();

        this.tipoIngreso = this.$route.params.tipoIngreso ?? 1
        this.tipoLogin = this.$route.params.tipoLogin ?? 2
        if(this.tipoIngreso == 1){
            await this.consultarProyecto();
            await this.consultarPaisesProyecto(this.proyecto.datos.id);
        }
    },
    methods: {
        ...mapMutations({
            almacenarError: `${VUEX_RUTA}/ALMACENAR_ERROR`,
            removerErrores: `${VUEX_RUTA}/REMOVER_ERRORES`,
            setPhoneNumber: "register/setPhoneNumber",
            setUsuarioModel: "register/setUsuarioModel",
            setIdUser: "register/setIdUser",
        }),
        ...mapActions({
            intentarIniciarSesion: `${VUEX_RUTA}/intentarIniciarSesion`,
            intentarIniciarSesionSocial: `${VUEX_RUTA}/intentarIniciarSesionSocial`
        }),
        async consultarProyecto(){
            try {
                const { data: { data }, } = await ServicioApi.consultarProyecto();
                this.proyecto.datos = data;
            } catch (error){
                this.error_catch(error);
            }
        },
        async consultarPaisesProyecto(proyecto){
            try {
                const { data: { data }, } = await ServicioApi.consultarPaises(proyecto);
                this.proyecto.paises = data;
            } catch (error){
                this.error_catch(error);
            }
        },
        alternarCargando(){
            this.cargando = !this.cargando
        },
        actualizarRuta(){
            this.$route.params.texto = 'Número de celular'
        },
        handle(){
            if (this.tipoIngreso == 2){
                this.iniciarSesion()
            } else {
                this.iniciarSesionFirebase()
            }
        },
        async iniciarSesion(){
            if(!this.payload.usuario) return;
            this.removerErrores();
            this.alternarCargando()
            try {
                this.payload.tipo = this.tipoLogin

                const { data } = await authService.generarCodigoTemporalLogin(this.payload)
                this.setIdUser(data.usuario)
                this.setUsuarioModel({usuario: this.payload.usuario})
                // this.$refs.CodigoVerificacionLogin.toggle(!isEmail);
                this.seccionIngreso = false
            } catch (error){
                this.$store.commit('auth/ALMACENAR_ERROR', error)
                // this.error_catch(error)
            } finally {
                this.alternarCargando();

            }

        },
        async iniciarSesionFirebase(){
            try {
                this.showErrorAlert = false
                if(!this.pais) return this.notificacion('', 'Debe seleccionar un país', 'error')
                if(!this.payload.usuario) return

                const { data } = await authService.noExistsUser({usuario: this.payload.usuario})
                console.log({exists: data.exists});
                if(!data.exists){
                    this.showErrorAlert = true
                    this.errorAlertType = 4
                    return
                }
                if(data.exists){
                    if (data.user.tipo == 5){
                        this.showErrorAlert = true
                        this.errorAlertType = 2
                        return
                    }

                    if (data.user.tipo == 1){
                        this.showErrorAlert = true
                        this.errorAlertType = 1
                        return
                    }
                }


                const { indicativo } = this.proyecto.paises.find((el) => el.id == this.pais)
                const phoneNumber = `+${indicativo}${this.payload.usuario}`
                this.setPhoneNumber(phoneNumber)
                const datos = await registroService.registrar(phoneNumber)

                if(datos){

                    let obj = {
                        usuario: this.payload.usuario,
                    }
                    this.setUsuarioModel(obj)
                    this.seccionIngreso = false
                    // this.$refs.CodigoVerificacion.toggle()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async cambioIngreso(){
            if(this.tipoIngreso == 1){
                this.tipoIngreso = 2
            }else if(this.tipoIngreso == 2){
                this.tipoIngreso = 1
                await this.consultarProyecto();
                await this.consultarPaisesProyecto(this.proyecto.datos.id);
            }
        },
        async iniciarSesionGoogle(){
            const proveedor = new firebase.auth.GoogleAuthProvider();
            proveedor.addScope('email');
            this.abrirPopoverConexion(proveedor);
        },
        iniciarSesionFacebook(){
            const proveedor = new firebase.auth.FacebookAuthProvider();
            proveedor.addScope('email');
            this.abrirPopoverConexion(proveedor)
        },
        async abrirPopoverConexion(proveedor){
            try {
                this.alternarCargando();
                this.removerErrores();

                const { credential: credenciales, user } = await firebase.auth().signInWithPopup(proveedor);

                if (!credenciales) return;

                const payload = {
                    provider: this.obtenerNombreProveedor(user),
                    token: credenciales.accessToken,
                    tipo: this.tipoLogin
                };

                await this.intentarIniciarSesionSocial(payload).catch(this.almacenarError);
                this.intentarIrAVerificacion();
            } catch (error){
                console.error(error);
            } finally {
                this.alternarCargando();
            }
        },
        obtenerNombreProveedor: (obj) => obj.providerData[0].providerId.split('.')[0],
        intentarIrAVerificacion(){
            if (this.existeErrorServidor || this.existeErrorSesion) return

            this.$router.push({ name: 'login.stop' })
        },
    }

}
</script>

<style lang="scss">
.br-12{
    border-radius: 12px !important;
}
.alert-message{
    box-shadow: 0px 1px 4px #00000014;
    width: 338px;
    height: 138px;
    &::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 38px;
        bottom: auto;
        border: 25px solid;
        border-color: transparent transparent transparent white;
    }
}
.volver{
    top: 10px;
    left: 15px;
}
.text-green{
    color: #28D07B !important;
}
</style>
